.home-anuncios-container {
    background-color: #F8F9FD;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 35px;
    display: flex;
}

.home-anuncios-content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.home-anuncios-novedades-titulo {
    color: #EB8B3D;
    font-weight: bold;
    flex-grow: 2;
    margin-right: 25px;
}

.home-anuncios-novedades-cuerpo {
    flex-grow: 2;
}

.home-contratos-cuerpo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-contratos-cuerpo .modalTable td {
    background-color: #F8F9FD;
}

.error-message {
    text-align: center;
}

@media (max-width: 700px) {
    .home {
        width: 100%;
    }

    .home-anuncios-container {
        width: 100%;
        padding: 25px;
    }

    .home-anuncios-content {
        width: 100%;
    }

    .home-anuncios-novedades-titulo {
        margin-right: 50px;
    }
}

@media (max-width: 250px) {
    .home-anuncios-novedades-titulo {
        text-align: center;
        width: 100%;
    }

    .home-anuncios-content {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
}
