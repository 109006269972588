.mainLogo {
    width: 200px;
    height: 200px;
    margin: auto;
}

.login {
    display: flex;
    justify-content: center;
}

.loginForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.loginForm .genericLinkElement {
    margin: auto;
    margin-top: 25px;
}

.loginForm .genericLinkElement a:link,
.loginForm .genericLinkElement a:visited,
.loginForm .genericLinkElement a:active {
    color: #595959;
}

.loginForm .btn {
   background-color: #1A3886;
   width: 30%;
   color: white;
   margin: auto;
   margin-top: 25px;
}

.login .genericFormInput {
    width: 50vw;
}

@media (max-width: 460px) {
    .mainLogo {
        width: 150px;
        height: 150px;
        margin: auto;
    }

    .loginForm .btn {
        width: 80%;
    }
}

@media (max-width: 1100px) {
    .login .genericFormInput {
        width: 70vw;
    }
}

@media (max-width: 750px) {
    .login .genericFormInput {
        width: 80vw;
    }
}