.menuContainer {
    margin: 0;
    padding: 0;
    width: 23%;
    background-color: #E7EAF2;
    height: 100vh;
}

.menu-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-logo {
    width: 130px;
    height: 130px;
}

.menu-icon {
    color: #1A3886;
    vertical-align: middle;
    font-size: 30px !important;
}

.menu li {
    display: flex;
    justify-content: center;
    font-family: 'Amaranth', 'Verdana';
    padding-bottom: 25px;
}

.menu li a:link,
.menu li a:visited,
.menu li a:active {
    margin-left: 15px;
    color: black;
    font-size: 22px;
}

.menu-option-link {
    vertical-align: middle;
}

@media (min-width: 750px) {
    .menu-logo-container .menu-icon {
        display: none;
    }
}

@media (max-width: 1100px) {
    .menu li {
        margin: auto;
        width: 50%;
    }

    .menu li a:link,
    .menu li a:visited,
    .menu li a:active {
        margin-left: 15px;
        font-size: 20px;
    }
}

@media (max-width: 750px) {
    .menuContainer {
        cursor: pointer;
        background-color: white;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .menuContainer-unhidden {
        width: 100%;
        background-color: #E7EAF2;
        height: 100%;
    }

    .menu {
        display: none;
    }

    .menu li {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .menu-unhidden {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .menu-logo {
        width: 20%;
        height: 20%;
    }

    .menu-logo-container .menu-icon {
        margin-left:15px;
    }

    .menu-icon {
        font-size: 20px !important;
    }

    .menu li {
        width: 60%;
    }

    .menu li a:link,
    .menu li a:visited,
    .menu li a:active {
        margin-left: 5px;
        font-size: 15px;
    }
}

@media (max-width: 590px) {
    .menu-icon {
        font-size: 15px !important;
    }

    .menu li {
        width: 70%;
    }
}

@media (max-width: 470px) {
    .menu-logo {
        width: 100px;
        height: 100px;
    }

    .menu li {
        width: 80%;
    }
}
