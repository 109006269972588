.puntos-cuerpo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.puntos-todos-los-contratos-filter-box-container {
    margin: auto;
    width: 50%;
    border: solid 1px lightgrey;
}

.puntos-todos-los-contratos-filter-box {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.puntos-todos-los-contratos-filter-box a {
    text-transform: none;
    color: #9e9e9e;
    font-size: 1rem;
}

.checkbox-puntos-filter[type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: 2px solid #EB8B3D;
    background-color: #EB8B3D;
}

.todos-los-puntos-container {
    margin-top: 25px;
    margin-bottom: 25px;
}

.todos-los-puntos-container .modalTable td {
    background-color: white;
}

.todos-los-puntos-container .modalTable .puntos-modal-table td {
    background-color: #F8F9FD;
}

.puntos-cuerpo .modalTable td {
    background-color: #F8F9FD;
}

.btn.btn-flat:hover {
    background-color: transparent !important;
    text-decoration: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 3px;
}