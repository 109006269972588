.admin {
    display: flex;
    flex-direction: column;
    width: 77%;
}

.admin img {
    width: 100px;
    height: 100px;
    margin: auto;
}

.admin .row {
    margin-top: 25px;
}

.tabs {
    height: 100px;
    width: 70%;
}

.tabs .tab a{
    color: #1A3886 !important;
} /* Tabs text color */

.tabs .tab a:hover {
    background-color: #1A3886 !important;
    color: #FFF !important;
    font-weight: bold;
} /* Text color on hover */

.tabs .tab a.active {
    background-color: #E3EAFA !important;
    color: #1A3886 !important;
} /* Background and text color when a tab is active */

.tabs .indicator {
    background-color: #1A3886 !important;
} /* Color of underline */

.admin-clients, .adminPointsContainer {
    background-color: #F8F9FD;
    min-height: 300px;
}

.adminPointsContainer {
    margin-top: -15px;
    padding-top: 50px;
    padding-left: 25px;
}

.admin-icon-background {
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.admin-edit-icon {
    color: black;
    vertical-align: middle;
    font-size: 30px !important;
}

.admin-delete-icon {
    color: #EB8B3D;
    vertical-align: middle;
    font-size: 30px !important;
}

.modification-column {
    display: flex;
    justify-content: space-around;
}

.admin-logout-footer {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.admin-actions {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.new-client-container, .news-container {
    display: flex;
    justify-content: flex-end;
}

.new-client, .add-news {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.new-client-container .modal-footer,
.delete-client-container .modal-footer,
.modify-client-container .modal-footer,
.news-container .modal-footer {
    display: none;
}

.new-client-container .modal-content {
    background-color: #E3EAFA !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.delete-client-container .modal-content {
    background-color: #F4B27B !important;
}

.adminAddNewClientForm {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.adminAddNewClientForm .genericFormInput {
    width: 300%;
}

.adminDeleteClientForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.adminAddNewClientForm .btn,
.adminDeleteClientForm .btn {
    width: 30%;
    margin: auto;
}

.adminPointsForm {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.file-upload-container {
    display: flex;
    align-items: center;
    flex-direction: row;
}