.cambio-clave-cuerpo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cambio-clave-cuerpo form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 70%;
}

.cambio-clave-button {
    width: 20%;
    margin: auto;
}

@media (max-width: 600px) {
    .cambio-clave-button {
        width: 50%;
        margin: auto;
    }
}