.preloader-container {
    background-color: #E7EAF2;
    position: fixed;
    left: 50%;
    top: 35%;
    margin-left: -150px;
    z-index: 1000;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    vertical-align: center;
    flex-direction: column;
}

.generic-preloader {
    margin: auto;
}

.preloader-text {
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
}