.contacto-cuerpo {
    margin: auto;
    width: 70%;
}

.contacto-cuerpo form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.contact-button {
    width: 20%;
    margin: auto;
}

.whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

@media (max-width: 600px) {
    .contact-button {
        width: 50%;
        margin: auto;
    }
}