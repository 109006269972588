html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow:visible;
}

.App {
    display: flex;
    justify-content: center;
}

h1 {
    font-size: 30px;
    margin-left: 50px;
}

.text-center {
    text-align: center;
}

.text-bold {
    font-weight: bold;
}

@media (max-width: 750px) {
    .App {
        flex-direction: column;
    }

    h1 {
        font-size: 25px;
    }
}

@media (max-width: 250px) {
    h1 {
        font-size: 20px;
    }
}
